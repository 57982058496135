@import '../Global';

.MainSite {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: absolute;

  .TopSubdomainBar {
    .TabItem {
      font-size: min(25px, 8cqw);
      line-height: max(min(8cqw, 25px), 44px);
    }
  }
  .TopTabBar {
    .TabItem {
      font-size: min(25px, 4cqw);
      // Keep the height at least at 44px for mobile screen compatibility
      line-height: max(min(4cqw, 25px), 44px);
    }
  }
  .TabBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px $border-color;
    background-color: $theme-color;
    color: $theme-text-color;
    container-type: inline-size;

    .TabItem {
      flex: 1;
      text-align: center;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Chrome/Safari/Opera */
      -khtml-user-select: none; /* Konqueror */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                              not supported by any browser */

      &.Selected {
        background-color: #67A1FF;
      }

      &.Disabled {
        background-color: gray;
        color: #3d3d3d;
        cursor: not-allowed;
      }

      &:not(:last-child) {
        border-right: solid 1px $border-color;
      }
    }
  }

  .PageHolder {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    background-color: $background-color;
  }
}