@import '../../Global';

.PortfolioPage {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: $background-color;
  color: $background-text-color;

  .PortfolioSection {
    padding-top: 15px;

    .PortfolioSectionHeader {
      .Title {
        font-size: 20px;
        display: inline-block;
        font-weight: bold;
      }
      .DateRange {
        vertical-align: top;
        margin-right: 5px;
        margin-top: 5px;
        float: right;
        display: inline-block;
      }
    }
    .Projects {
      .PortfolioProject {
        background-color: $theme-color;
        color: $theme-text-color;
        border: solid 1px $border-color;
        border-radius: 5px;
        margin-left: 30px;
        margin-top: 5px;

        .Header {
          margin-top: 5px;
          .Title {
            font-weight: bold;
            display: inline-block;
            margin-left: 5px;
          }
          .Date {
            float: right;
            display: inline-block;
            margin-right: 5px;
          }
        }
        .Content {
          margin-top: 5px;
          margin-right: 5px;
          margin-left: 5px;
        }
        .FooterHR {
          margin-bottom: 0;
        }
        .Footer {
          margin-top: 2px;
          margin-bottom: 2px;
        }
        .Tools {
          text-align: right;
          margin-right: 5px;
          white-space: pre-wrap;
          overflow-wrap: break-word;
        }
      }
    }
  }
}