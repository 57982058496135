@import '../../Global';

.ProjectsPage {
  .GithubDiv {
    border-bottom: solid 1px $border-color;
    background-color: $theme-color;
    color: $theme-text-color;
    margin: auto;
    padding: 5px;
    text-align: center;
    font-size: 25px;
  }
  .TryOutDiv {
    margin: auto;
    padding-top: 10px;
    padding-left: 10px;
    text-align: left;
    font-size: 25px;
  }
  .ProjectsPost {
    background-color: $theme-color;
    color: $theme-text-color;
    border: solid 1px $border-color;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    padding: 5px;

    .OpenHouse {
      content: url("../../../static/assets/OpenHouseTara.png");
      max-width: 100%;
      width: 600px;
    }

    .GameDisplay {
      content: url("../../../static/assets/OpenHouseGame.png");
      max-width: 100%;
      width: 600px;
    }

    .Doge {
      content: url("../../../static/assets/Doge.png");
    }

    .Header {
      width: 100%;
      display: flex;

      .Title {
        flex: 1;
        font-size: 25px;
        text-decoration: underline;
      }
      .Date {
        width: 100px;
      }
    }
    .Description {

      img {
        vertical-align: top;
      }
    }
  }
  .ReversiHeader {
    z-index: 2;
    position: absolute;
  }
  .ReversiContainer {
    text-align: center;
  }
  .ReversiPlayDiv {

  }
  .ReversiImg {
    max-width:min(100%, 400px);
    height: auto;
    margin: auto;
    clip-path: inset(0px 1px 2px 1px);
    background-image: url('../../../static/assets/ReversiStill.jpeg');
    background-position: center;
    background-size: contain;
  }
  .ReversiPost {
    background-color: rgb(177, 219, 233);
    position: relative;
  }

  .ArtGameHeader {
    position: absolute;
  }
  .ArtGameContainer {
    text-align: center;
    margin-top: 30px;
  }
  .ArtGamePlayDiv {

  }
  .ArtGameImg {
    max-width:min(100%, 600px);
    height: auto;
    margin: auto;
    border: 1px solid black;
    background-image: url('../../../static/assets/ArtGameStill.jpeg');
    background-position: center;
    background-size: contain;
  }
  .ArtGamePost {
    //background-color: lightblue;
    position: relative;
  }
  .ArtGameText {

  }

  .ForumHeader {
    position: absolute;
  }
  .ForumContainer {
    text-align: center;
    margin-top: 10px;
  }
  .ForumPlayDiv {

  }
  .ForumImg {
    max-width:min(100%, 600px);
    height: auto;
    margin: auto;
    border: 1px solid black;
    background-image: url('../../../static/assets/ForumStill.jpg');
    background-position: center;
    background-size: contain;
  }
  .ForumPost {
    //background-color: #090909;
    position: relative;
    //color: white;
  }
  .ForumText {
  }
}