@import '../../Global';

.ResumePage {
  font-size: 16px;
  padding-left: 10px;
  background-color: $background-color;
  color: $background-text-color;

  .Name {
    font-size: 25px;
    text-align: center;
  }
  .Title {
    font-size: 15px;
    text-align: center;
    color: #1a1a1a;
  }
  .Contact {
    text-align: center;
    width: 100%;
    display: block;
  }
  .MainHeader {
    font-weight: bold;
    font-size: 20px;
  }
  .MainHR {
    margin-top: 2px;
    margin-left: 0;
    width: calc(100% - 20px);
  }
  .QualificationsList {
    margin-top: 5px;
    .ListElement {

    }
  }
  .PlaceLocationDate {
    .Place {
      display: inline-block;
      font-weight: bold;
      margin-right: 4px;
    }
    .Location {
      display: inline-block;
    }
    .Date {
      display: inline-block;
      float: right;
      margin-right: 20px;
    }
  }
  .Position {
      font-weight: bold;
  }
  .PositionDescription {

  }
  .WorkTasks {
    margin-top: 5px;
    .ListElement {

    }
  }
  .Degree {
    padding-bottom: 10px;
  }

  .CertificationAndDate {
    .Certification {
      display: inline-block;
      font-weight: bold;
      margin-right: 4px;
    }
    .Date {
      display: inline-block;
      float: right;
      margin-right: 20px;
    }
  }
  .CertificationText {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    padding-bottom: 10px;
  }

  .PublicationAndDate {
    .Publication {
      display: inline-block;
      font-weight: bold;
      margin-right: 4px;
    }
    .Date {
      display: inline-block;
      float: right;
      margin-right: 20px;
    }
  }
  .PublicationText {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    padding-bottom: 10px;
  }
}
