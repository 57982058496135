@import '../../Global';

.ContactPage {
  width: 100%;
  height: 100%;
  display: flex;

  .ContactDiv {
    border: solid 1px $border-color;
    background-color: $theme-color;
    color: $theme-text-color;
    margin: auto;
    padding: 5px;
    text-align: center;
    font-size: 25px;

    .ContactText {

    }
    .ContactLink {

    }
  }

}