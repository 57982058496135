@import '../../Global';

.BiographyPage {
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  background-color: $background-color;
  color: $background-text-color;
  padding-left: 2%;
  padding-right: 2%;

  .BiographyText {
  }

  .SelfPictureDiv {
    border: solid 1px $border-color;
    margin-right: 15px;
    margin-top: 3px;
    display: inline-block;
    float: left;
    clear: left;
    text-align: center;
    background-color: $background-color;

    .SelfPicture {
      height: 200px;

      img {
        height: 100%;
      }
    }

    .SelfPictureText {
      border-top: solid 1px $border-color;
      font-size: 20px;
    }
  }
  .WifePictureDiv {
    border: solid 1px black;
    margin-left: 15px;
    margin-top: 10px;
    display: inline-block;
    float: right;
    clear: right;
    text-align: center;
    background-color: $background-color;

    .WifePicture {
      height: 200px;

      img {
        height: 100%;
      }
    }

    .WifePictureText {
      border-top: solid 1px $border-color;
      font-size: 20px;
    }
  }

  .CatPictureDiv {
    border: solid 1px $border-color;
    margin-right: 15px;
    margin-top: 10px;
    display: inline-block;
    float: left;
    clear: left;
    text-align: center;
    background-color: $background-color;

    .CatPicture {
      height: 200px;

      img {
        height: 100%;
      }
    }

    .CatPictureText {
      border-top: solid 1px $border-color;
      font-size: 20px;
    }
  }
}